import { createUseStyles } from "react-jss";

export default createUseStyles({
  snakeFood: {
    position: "absolute",
    width: "2%",
    height: "2%",
    backgroundColor: "red",
    border: "1px solid #000",
    zIndex: "1"
  }
});