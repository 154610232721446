import React, { useState } from "react";

import img1 from "./images/item-1.jpeg";
import img2 from "./images/item-2.jpeg";
import img3 from "./images/item-3.jpeg";
import img4 from "./images/item-4.jpeg";
import img5 from "./images/item-5.jpeg";
import img6 from "./images/item-6.jpeg";
import img7 from "./images/item-7.jpeg";
import img8 from "./images/item-8.jpeg";
import img9 from "./images/item-9.jpeg";
import img10 from "./images/item-10.jpeg";

const Allmenu = [
  {
    id: 1,
    title: "buttermilk pancakes",
    category: "breakfast",
    price: 15.99,
    img: img1,
    desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
  },
  {
    id: 2,
    title: "diner double",
    category: "lunch",
    price: 13.99,
    img: img2,
    desc: `vaporware iPhone mumblecore selvage raw denim slow-carb leggings gochujang helvetica man braid jianbing. Marfa thundercats `,
  },
  {
    id: 3,
    title: "godzilla milkshake",
    category: "shakes",
    price: 6.99,
    img: img3,
    desc: `ombucha chillwave fanny pack 3 wolf moon street art photo booth before they sold out organic viral.`,
  },
  {
    id: 4,
    title: "country delight",
    category: "breakfast",
    price: 20.99,
    img: img4,
    desc: `Shabby chic keffiyeh neutra snackwave pork belly shoreditch. Prism austin mlkshk truffaut, `,
  },
  {
    id: 5,
    title: "egg attack",
    category: "lunch",
    price: 22.99,
    img: img5,
    desc: `franzen vegan pabst bicycle rights kickstarter pinterest meditation farm-to-table 90's pop-up `,
  },
  {
    id: 6,
    title: "oreo dream",
    category: "shakes",
    price: 18.99,
    img: img6,
    desc: `Portland chicharrones ethical edison bulb, palo santo craft beer chia heirloom iPhone everyday`,
  },
  {
    id: 7,
    title: "bacon overflow",
    category: "breakfast",
    price: 8.99,
    img: img7,
    desc: `carry jianbing normcore freegan. Viral single-origin coffee live-edge, pork belly cloud bread iceland put a bird `,
  },
  {
    id: 8,
    title: "american classic",
    category: "lunch",
    price: 12.99,
    img: img8,
    desc: `on it tumblr kickstarter thundercats migas everyday carry squid palo santo leggings. Food truck truffaut  `,
  },
  {
    id: 9,
    title: "quarantine buddy",
    category: "shakes",
    price: 16.99,
    img: img9,
    desc: `skateboard fam synth authentic semiotics. Live-edge lyft af, edison bulb yuccie crucifix microdosing.`,
  },
  {
    id: 10,
    title: "bison steak",
    category: "dinner",
    price: 22.99,
    img: img10,
    desc: `skateboard fam synth authentic semiotics. Live-edge lyft af, edison bulb yuccie crucifix microdosing.`,
  },
];

const Menu = () => {
  const [menu, setMenu] = useState(Allmenu);
  const [categories] = useState(
    Allmenu.reduce(
      function (values, item) {
        if (!values.includes(item.category)) {
          values.push(item.category);
        }
        return values;
      },
      ["all"]
    )
  );

  const categoryClick = (e) => {
    let category = e.currentTarget.dataset.id;
    if (category === "all") setMenu(Allmenu);
    else setMenu(Allmenu.filter((item) => item.category === category));
  };

  return (
    <div className="mySass Menu">
      <section class="menu">
        <div class="title">
          <h2>our menu</h2>
          <div class="underline"></div>
        </div>
        <div class="btn-container">
          {categories.map((category) => (
            <button
              type="button"
              class="filter-btn"
              data-id={category}
              onClick={(e) => categoryClick(e)}
            >
              {category}
            </button>
          ))}
        </div>
        <div class="section-center">
          {menu.map((item) => (
            <article class="menu-item">
              <img src={item.img} alt={item.title} class="photo" />
              <div class="item-info">
                <header>
                  <h4>{item.title}</h4>
                  <h4 class="price">{item.price}</h4>
                </header>
                <p class="item-text">{item.desc}</p>
              </div>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Menu;
