import React, { Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// MUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    ...theme.spread,
    commentImage: {
        width: 100,
        height: 100,
        objectFit: 'cover',
        borderRadius: '50%'
    },
    commentData: {
        marginLeft: 20
    }
});

function Comments({ comments, classes }) {
    return (
        <Grid container>
            {comments && comments.map((comment, index) => {
                const { body, createdAt, userImage, userHandle } = comment;
                return (
                    <Fragment key={createdAt}>
                        <Grid item sm={12}>
                            <Grid container>
                                <Grid item sm={2}>
                                    <img
                                        src={userImage}
                                        alt="comment"
                                        className={classes.commentImage}
                                    />
                                </Grid>
                                <Grid item sm={9}>
                                    <div className={classes.commentData}>
                                        <Typography
                                            variant="h5"
                                            component={Link}
                                            to={`/socialMediaApp/users/${userHandle}`}
                                            color="primary"
                                        >
                                            {userHandle}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                                        </Typography>
                                        <hr className={classes.invisibleSeparator} />
                                        <Typography variabnt="body1">{body}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {index !== comments.length - 1 && (
                            <hr className={classes.visibleSeparator} />
                        )}
                    </Fragment>
                );
            })}
        </Grid>
    )
}

export default withStyles(styles)(Comments);
